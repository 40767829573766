<template>
  <div id="round-overall-bottom" class="p-2">

    <div class="my-2 form-inline">
      <b-input-group size="sm" prepend="ค้นหา">
        <b-form-input v-model="findNumbers" placeholder="ค้นหาหมายเลข"></b-form-input>
      </b-input-group>

      <b-input-group size="sm" prepend="เรียงลำดับ" class="ml-1">
        <b-form-select v-model="sortBy">
          <b-form-select-option value="number">หมายเลข</b-form-select-option>
          <b-form-select-option value="bill">จำนวนโพย</b-form-select-option>
          <b-form-select-option value="amount">ยอดทั้งหมด</b-form-select-option>
          <b-form-select-option value="received">ถือหุ้น</b-form-select-option>
        </b-form-select>
      </b-input-group>

      <b-input-group size="sm" prepend="เรียงจาก" class="ml-1">
        <b-form-select v-model="sortOrder">
          <b-form-select-option value="asc">น้อย > มาก</b-form-select-option>
          <b-form-select-option value="desc">มาก > น้อย</b-form-select-option>
        </b-form-select>
      </b-input-group>

      <b-input-group size="sm" prepend="จำนวนแถว" class="ml-1">
        <b-form-select v-model="rowLength">
          <b-form-select-option :value="10">10</b-form-select-option>
          <b-form-select-option :value="50">50</b-form-select-option>
          <b-form-select-option :value="75">75</b-form-select-option>
          <b-form-select-option :value="100">100</b-form-select-option>
        </b-form-select>
      </b-input-group>
    </div>

    <table class="table table-secondary table-sm table-bordered table-hover mb-0">
      <thead>
        <tr>
          <th rowspan="2" width="40" class="border-right border-left border-top">ลำดับ</th>
          <th colspan="4" class="border-right border-top">2 ตัวล่าง</th>
          <th colspan="4" class="border-right border-top">วิ่งล่าง</th>
        </tr>
        <tr>
          <td width="100">หมายเลข</td>
          <td width="80">โพย</td>
          <td width="100">ยอดทั้งหมด</td>
          <td width="100" class="border-right">ถือหุ้น</td>

          <td width="100">หมายเลข</td>
          <td width="80">โพย</td>
          <td width="100">ยอดทั้งหมด</td>
          <td width="100" class="border-right">ถือหุ้น</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in items" :key="item.two.twoNumber+item.one.oneNumber">
          <td class="text-center text-secondary border-left border-right">{{ i+1 }}</td>
          <td class="text-center text-primary"><span class="badge badge-primary">{{item.two.twoNumber}}</span></td>
          <td
            class="text-center"
          >{{item.two.twoNumberBottom.length | amountFormat(0, '-')}}</td>
          <td
            class="text-right"
            :class="[{'alert-primary':item.two.twoNumberBottom.amount>0}]"
          >{{item.two.twoNumberBottom.amount | amountFormat(2, '-')}}</td>
          <td
            class="text-right border-right"
            :class="[{'alert-success':item.two.twoNumberBottom.received>0}]"
          >{{item.two.twoNumberBottom.received | amountFormat(2, '-')}}</td>

          <td class="text-center text-primary"><span class="badge badge-primary">{{item.one.oneNumber}}</span></td>
          <td
            class="text-center"
          >{{item.one.runBottom.length | amountFormat(0, '-')}}</td>
          <td
            class="text-right"
            :class="[{'alert-primary':item.one.runBottom.amount>0}]"
          >{{item.one.runBottom.amount | amountFormat(2, '-')}}</td>
          <td
            class="text-right border-right"
            :class="[{'alert-success':item.one.runBottom.received>0}]"
          >{{item.one.runBottom.received | amountFormat(2, '-')}}</td>
        </tr>
        <tr v-if="!items.length && !isLoading">
          <td colspan="8" class="text-center">ไม่มียอดแทง</td>
        </tr>
        <tr v-else-if="isLoading">
          <td colspan="8" class="alert-warning text-center">กรุณารอซักครู่...</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import _ from 'lodash'

export default {
  name: 'RoundOverallBottom',
  props: ['data', 'isLoading'],
  data() {
    return {
      rowLength: 50,
      sortBy: 'number',
      sortOrder: 'asc',
      findNumbers: '',
      profit: null,
      profitAmount: 0
    }
  },
  computed: {
    twoNumberItems() {
      const numbers = []
      for(let i=0; i<=99; i++) {
        const twoNumber = i.toString().padStart(2, '0')
        numbers.push({
          twoNumber: twoNumber,
          twoNumberBottom: {
            length: this.data.twoNumberBottom?.[twoNumber]?.length || 0,
            amount: this.data.twoNumberBottom?.[twoNumber]?.amount || 0,
            received: this.data.twoNumberBottom?.[twoNumber]?.received || 0
          }
        })
      }

      return _.sortBy(numbers.filter((item)=>{
        if(this.findNumbers) {
          return `+${item.twoNumber}`.endsWith(this.findNumbers.slice(-2))
        }else{
          return true // item.twoNumberBottom.length > 0
        }
      }), (item)=>{
        return {
          number: this.sortOrder==='asc' ? parseInt(item.twoNumber) : -parseInt(item.twoNumber),
          bill: this.sortOrder==='asc' ? item.twoNumberBottom.length : -item.twoNumberBottom.length,
          amount: this.sortOrder==='asc' ? item.twoNumberBottom.amount : -item.twoNumberBottom.amount,
          received: this.sortOrder==='asc' ? item.twoNumberBottom.received : -item.twoNumberBottom.received
        }[this.sortBy]
      })
    },
    oneNumberItems() {
      const numbers = []
      for(let i=0; i<=9; i++) {
        const oneNumber = i.toString()
        numbers.push({
          oneNumber: oneNumber,
          runBottom: {
            length: this.data.runBottom?.[oneNumber]?.length || 0,
            amount: this.data.runBottom?.[oneNumber]?.amount || 0,
            received: this.data.runBottom?.[oneNumber]?.received || 0
          }
        })
      }

      return _.sortBy(numbers.filter((item)=>{
        if(this.findNumbers) {
          return this.findNumbers.split("").includes(item.oneNumber)
        }else{
          return true // item.runBottom.length > 0
        }
      }), (item)=>{
        return {
          number: this.sortOrder==='asc' ? parseInt(item.oneNumber) : -parseInt(item.oneNumber),
          bill: this.sortOrder==='asc' ? item.runBottom.length : -item.runBottom.length,
          amount: this.sortOrder==='asc' ? item.runBottom.amount : -item.runBottom.amount,
          received: this.sortOrder==='asc' ? item.runBottom.received : -item.runBottom.received
        }[this.sortBy]
      })
    },
    items() {
      let groupMaxLength = this.twoNumberItems.length > this.oneNumberItems.length ? this.twoNumberItems.length : this.oneNumberItems.length

      const items = []
      for(let i=0; i<groupMaxLength; i++) {
        items.push({
          two: this.twoNumberItems[i] || {
            twoNumber: '',
            twoNumberBottom: {
              length: 0,
              amount: 0,
              received: 0
            }
          },
          one: this.oneNumberItems[i] || {
            oneNumber: '',
            runBottom: {
              length: 0,
              amount: 0,
              received: 0
            }
          }
        })
      }

      if(!items.length)
        return []

      const chunks = _.chunk(items, this.rowLength)
      return chunks[0]
    }
  }
}
</script>
<style lang="scss" scoped>
#round-overall-bottom {
  .table {
    font-size: 90%;

    thead {
      tr {
        th, td {
          text-align: center;
          vertical-align: middle;
        }
      }
    }
    tbody {
      background-color: #FFF;
    }

    th, td {
      .badge {
        font-size: 100%;
      }
      &.border-right {
        border-right: 3px solid #b3b7bb !important;
      }
      &.border-top {
        border-top: 3px solid #b3b7bb !important;
      }
      &.border-left {
        border-left: 3px solid #b3b7bb !important;
      }
      &.border-bottom {
        border-bottom: 3px solid #b3b7bb !important;
      }
    }
  }
}
</style>
